import React, {useState, useEffect} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layoutSecure'
import sanityClient from '@sanity/client'
import {Carousel} from 'react-bootstrap'
import pdfIcon from '../images/icons/pdf-icon.png'
import { FaArrowRight, FaFilePdf } from 'react-icons/fa';
import Gallery from '../components/gallery'
import {toPlainText} from '../lib/helpers'
import Accordion from '../components/accordion'
import PortableText from '../components/portableText'
import {isUserInRegion} from '../services/auth'
import RestrictContentError from '../components/restrict-content-error'
import { useSelector, useDispatch } from "react-redux"
import FileIcon from '../components/fileIcon'
import {sanitizeFilename} from '../lib/helpers'
import FileComponent from '../components/fileComponent'


export const query = graphql`
    query BuildingTemplateQuery($id: String!) {
        page: sanityBuilding(id: {eq: $id}) {
            _id
            publishedAt
            title
            slug {
                current
            }
            banner {
                alt
                asset {
                    url
                    originalFilename
                }
            }         

            gallery {
                ... on SanityMainImage {
                    _key
                    _type
                    alt
                    asset{
                        url
                        mimeType
                        originalFilename
                    }
                }
                ... on SanityVideo {
                    _key
                    _type
                    thumbnail {
                      alt
                      asset {
                        url
                        originalFilename
                      }
                    }
                    file {
                      asset {
                        url
                        mimeType
                        originalFilename
                      }
                    }
                    region
                }                
            }

            mainReferences {
                ...SanityFile
                ...SanityLink
            }

            _rawPageDescription(resolveReferences: {maxDepth: 5})

            categories {
                title
                _rawDecription(resolveReferences: {maxDepth: 5})
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ...SanityLink
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }

                    ... on SanityLightboxWithDetailPage {
                        _key
                        _type
                        _rawDecription(resolveReferences: {maxDepth: 5})
                        title
                        subTitle
                        sectionLinks {
                          ...SanityFile
                          ...SanityLink
                        }
                        files {                         
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                asset{
                                    url
                                    mimeType
                                    originalFilename
                                }
                            }
                            ...SanityVideo
                        }
                        region
                        sectionDetailPages {
                            title
                            slug {
                                current
                            }
                            region
                        }
                    }

                    ...SanityCommonSection


                }
            }
            region
        }

        setting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }
    }
`


const BuildingTemplate = props => {
    const {data, errors} = props

    const page = data && data.page
    if(!page){
        throw new Error(
            'Missing "page data".'
        )
    }

    // console.log('----------> page data: ', page)

    let  banner = page.banner && page.banner.asset ? page.banner.asset.url : '#'

    const userInfo = useSelector(state => state.userInfo)

    let userRegion = userInfo ? userInfo.region : ''

    if(!isUserInRegion(userRegion, page.region)){
        return (
            <Layout>
                <Container>
                    <RestrictContentError />
                </Container>
            </Layout>
        )
    }

    let filteredMainRef = page.mainReferences.filter(item => isUserInRegion(userRegion, item.region))

    let setting = (data || {}).setting

    return (
        <Layout>
            {/* {errors && <SEO title='GraphQL Error' />}
            {post && <SEO title={post.title || 'Untitled'} description={toPlainText(post._rawExcerpt)} image={post.mainImage} />} */}

            {/* {page && <SEO title={page.title || 'Untitled'} description={toPlainText(page._rawPageDescription) || setting.description} />} */}
            {page && <SEO title={page.title || 'Untitled'} description={setting.description || ''} />}

            {errors && (
                <Container>
                    <GraphQLErrorList errors={errors} />
                </Container>
            )}

            
            
            {/* Main Content */}
            {/* image */}
            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={page.banner ? page.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className="mb-0" style={{color: 'rgb(245, 235, 220)'}}>{page.title.toUpperCase() || 'BUILDING NAME'}</h1>
                    </div>
                </div> */}
            </div>
            </div>


            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/building">Building</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{page.title || 'BUILDING NAME'}</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>
            
            {/* content */}
            {
                (page.gallery || filteredMainRef) && (page.gallery.length > 0 || filteredMainRef.length > 0) &&
                <section className="pt-0 pb-0 mb-4">
                    <div className="container">
                        <div className="row d-flex justify-content-lg-between">
                            
                            {(()=>{
                                // display gallery
                                let images = page.gallery && page.gallery.map(item => {
                                    if(item._type === 'mainImage'){
                                        return {
                                            original: item.asset ? item.asset.url : '',
                                            thumbnail: item.asset ? item.asset.url + '?w=250&h=150&fit=max' : ''
                                        }
                                    }
                                    else if(item._type === 'video' && item.file && item.file.asset.mimeType === 'video/mp4' && isUserInRegion(userRegion, item.region)){
                                        return {
                                            original: item.thumbnail && item.thumbnail.asset ? item.thumbnail.asset.url : '',
                                            thumbnail: item.thumbnail && item.thumbnail.asset ? item.thumbnail.asset.url + '?w=250&h=150&fit=max' : '',
                                            embedUrl: item.file && item.file.asset ? item.file.asset.url : '',
                                            videoType: 'selfHost'
                                        }
                                    }
                                })

                                let filterdImage = images.filter(x => x)
                                if(filterdImage && filterdImage.length > 0){
                                    // console.log('----------> filterdImage: ', filterdImage, page.gallery)
                                    return (
                                        <div className="col-12 col-md-7">
                                            <Gallery items={filterdImage} />
                                        </div>
                                    )
                                }
                            })()}

                            {
                                filteredMainRef && filteredMainRef.length > 0 &&
                                <div className="col-12 col-md-5">
                                    <table className="table table-bordered">
                                        <thead className="bg-footer text-primary-color">
                                            <tr>
                                                <th scope="col" className="col-6">Full set of files for download (ZIP)</th>
                                                <th scope="col" className="col-6">Package for reference only. Not for building purposes.</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                filteredMainRef.length > 0 &&
                                                filteredMainRef.map((item, i) => {
                                                    // if(!item.file) return
                                                    return(
                                                        <tr key={i}>
                                                            <td>
                                                                {/* icon */}
                                                                {item._type != 'fileComponent' && <FaArrowRight size='22' />
                                                                }

                                                                {/* title */}
                                                                {item._type === 'fileComponent' 
                                                                    ? <FileComponent item={item} />
                                                                    : <Link to={item.url || '#'}>{item.title || ''}</Link>
                                                                }
                                                            </td>
                                                            <td>
                                                                {item._type === 'fileComponent' && item.description ? item.description : item.title}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                        </div>
                    </div>
                </section>
            }


            {/* descriptive text */}
            {
                page._rawPageDescription && 
                <section className="pt-0 pb-0 mb-4">
                    <div className="container">
                        <PortableText blocks={page._rawPageDescription || ''} />
                    </div>
                </section>
            }
            


            {/* accordion */}
            <section className="pt-0 pb-0 mb-4">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 mb-12">
                            <Accordion items={page.categories} userRegion={userRegion} />     
                        </div>

                    </div>
                </div>
            </section>


        </Layout>
    )
}

export default BuildingTemplate
